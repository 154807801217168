import { Component, inject } from '@angular/core';
import { ClientService } from '../core/services/client.service';
import { Socket } from 'ngx-socket-io';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrl: './layouts.component.scss',
})
export class LayoutsComponent {
  client = inject(ClientService);
  socket = inject(Socket);
  sanitizer = inject(DomSanitizer);
  router = inject(Router);

  toast: any = null;

  alertOpen = false;

  cb = {
    startInterview: async (data: any) => {
      console.log(this.router, this.router.url);
      if (!this.router.url.startsWith('/interview/' + data.id)) {
        this.client.setToast({
          data: '면접이 시작되었습니다.',
          click: () => {
            this.router.navigate(['interview', data.id]);
            this.client.clearToast();
          },
        });
      }
    },
  };

  timer: any = null;

  async ngOnInit() {
    this.socket.on(`startInterview`, this.cb.startInterview);
    //this.socket.emit('joinRoom', 'interviewer' + this.client.interviewer.id);
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    this.socket.removeListener('startInterview', this.cb.startInterview);
  }

  onEvent(e: any) {
    console.log(e);
    if (e.id === 'alert') {
      if (e.data === 'toggle') {
        this.alertOpen = !this.alertOpen;
      } else if (e.data === 'close') {
        this.alertOpen = false;
      }
    }
  }
}
