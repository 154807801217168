import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutsComponent } from './layouts/layouts.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { authGuard } from './core/guards/auth.guard';
import { JoinMailComponent } from './pages/join-mail/join-mail.component';
import { JoinComponent } from './pages/join/join.component';
import { JoinAuthComponent } from './pages/join-auth/join-auth.component';
import { JoinMailInterviewerComponent } from './pages/join-mail-interviewer/join-mail-interviewer.component';
import { PasswordChangeComponent } from './pages/password-change/password-change.component';
import { TutorialComponent } from './pages/tutorial/tutorial.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [authGuard],
  },
  {
    path: 'auth/:auth',
    component: JoinMailComponent,
  },
  {
    path: 'auth2/:auth',
    component: JoinMailInterviewerComponent,
  },
  {
    path: 'join',
    component: JoinComponent,
  },
  {
    path: 'email/:email',
    component: JoinAuthComponent,
  },
  {
    path: 'password-change/:auth',
    component: PasswordChangeComponent,
  },
  {
    path: 'tutorial',
    component: TutorialComponent,
    canActivate: [authGuard],
  },
  {
    path: 'tutorial/:mode',
    component: TutorialComponent,
    canActivate: [authGuard],
  },
  {
    path: '',
    component: LayoutsComponent,
    loadChildren: () => import('./pages/pages.module'),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
